import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  h1 {
    margin: 0 0 30px 4px;
    color: var(--purple);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }
`;

const StyledPic = styled.div`
  position: relative;
  margin: 100px 0px 0px 0px;
  max-width: 450px;

  @media (max-width: 768px) {
    margin: 100px 0px 0px 0px;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    // background-color: var(--purple);

    .img {
      position: relative;
      border-radius: var(--border-radius);
      // mix-blend-mode: multiply;
      // filter: grayscale(20%);
      transition: var(--transition);
    }
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      // mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--dark-purple);
      top: 25px;
      left: 25px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const [isMounted, setIsMounted] = useState(false);
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi!</h1>;
  const two = <h2 className="big-heading">I'm Miseong,</h2>;
  const three = <h3>I develop and improve the full stack environment of web or software.</h3>;
  const four = (
    <div className="inner">
      <StyledText>
        <p>
          My IT journey started when I majored in multimedia at university. In the process of
          finding myself intact again, I recently studied IT web programming at college and tried to
          absorb everything, so this year I graduated with honours. <br />
          The&nbsp;
          <a href="/#skills" rel="noreferrer">
            skills{' '}
          </a>{' '}
          I have experienced can be found here, and{' '}
          <a href="/#testimonial" rel="noreferrer">
            {' '}
            people I've worked with say{' '}
          </a>
          &nbsp;like this about me.
        </p>
        <p>
          I really feel a sense of accomplishment and pleasure whenever I see a result that works
          well and gives users joy. I enjoy constantly &nbsp;
          <a href="/learning-log" rel="noreferrer">
            developing myself
          </a>
          &nbsp;and my surroundings through learning and problem solving.
        </p>
      </StyledText>
      <StyledPic>
        <div className="wrapper">
          <StaticImage
            className="img"
            src="../../images/headshot.png"
            width={500}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Headshot"
          />
        </div>
      </StyledPic>
    </div>
  );

  const items = [one, two, three, four];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledAboutSection>
  );
};

export default About;
